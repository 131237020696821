import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import { Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  clicked: {
    color: "black",
    marginLeft: "15%",
    fontSize: "18px",
    fontWeight: 500,
    borderLeft: "5px solid black",
    padding: "10% 0% 5% 10%",
  },
  unClicked: {
    margin: "10% 0% 5% 15%",
    fontSize: "18px",
    fontWeight: 500,
    color: "#000",
  },
}));

export function hasPermission(role, minimumRole) {
  const roles = ["client", "admin", "supervisor", "ceo"];
  if (!roles.includes(role) || !roles.includes(minimumRole)) {
    return null;
  }
  return roles.indexOf(role) >= roles.indexOf(minimumRole);
}

const AppDrawer = (props) => {
  const location = useLocation();
  const classes = useStyles();

  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [redirectToLogin, shouldRedirectToLogin] = useState(false);

  const access_to_form = [
    "devops@kodingtech.com",
    "admin@egt-bg.ro",
    "test@feg.eu",
    "test@seven.ro",
    "suport.sloturi@dms.ro",
    "gabriel.cabuz@dms.ro",
    "amir.agili@kodingtech.com",
    "test@egt.ro",
    "gavrila.andreea@feg.eu",
    "bogdan.mihalte@metropolisgrup.ro",
    "augustin.tarpian@rivergrup.ro",
    "diana.popescu@rivergrup.ro",
    "bogdan.ciofu@yahoo.com",
    "soso.maciuca@yahoo.com",
    "rodica.rus@rivergrup.ro",
    "lucia.boros@rivergrup.ro",
    "raluca.sauca@rivergrup.ro",
    "sorin.dunca@rivergrup.ro",
    "vicky.tardea@rivergrup.ro",
    "ion.sandru@rivergrup.ro",
    "simona.gale@metropolisgrup.ro",
    "florin.bobeica@metropolisgrup.ro",
    "mircea.radulescu@rivergrup.ro",
    "cristina.hettman@rivergrup.ro",
    "daniela.oleinici@rivergrup.ro",
    "marcoltgheorghe@icloud.com",
    "andreea.scortanu@rivergrup.ro",
    "moldovan.calin@revital-games.ro",
    "Ionut.talpa@rivergrup.ro",
    "georgiana.rosioru@feg.eu",
    "corina.oprea@feg.eu",
    "ramona.mindreanu@feg.eu",
    "grigorescu.alexandra@feg.eu",
    "alexandra.grigorescu@feg.eu",
    "admin@intergameselect.com",
    "d.gombos@seven.ro",
    "sz.jozsef@seven.ro",
    "fiordache@stanleybet.ro",
    "vitmesstilcontabilitate@gmail.com",
    "vladulescu1980@gmail.com",
    "vitmesstilgames@yahoo.ro",
    "dispecerat@stanleybet.ro",
    "razvan.binciu@egt-bg.com",
    "daniel.niculescu@egt-bg.com",
    "alexandru.ovedenie@egt.com",
    "silviu.stan@egt-bg.com",
    "transilvania.gold.games@gmail.com",
    "petronela.rizea@cellada.ro",
    "gamex_inter@yahoo.com",
    "cskgreatwin@outlook.com",
    "jeans_shop95@yahoo.co",
    "tatiana.aniculaesei@yahoo.com",
    "ciocan.lavinia@yahoo.com",
    "vierunicusor.96@gmail.com",
    "mindrescuoana@yahoo.com",
    "serban.georgiana15@yahoo.com",
    "carmenliliana2507@gmail.com",
    "barbu_camelia88@yahoo.com",
    "nicoletaovidenie@yahoo.com",
    "rebeca.calihman.btm@gmail.com",
    "cadu.daniel@yahoo.com",
    "rox_monde@yahoo.com",
    "player-003@cellada.ro",
    "player-006@cellada.ro",
    "player-008@cellada.ro",
    "player-011@cellada.ro",
    "player-012@cellada.ro",
    "player-014@cellada.ro",
    "player-015@cellada.ro",
    "player-017@cellada.ro",
    "player-018@cellada.ro",
    "player-019@cellada.ro",
    "player-021@cellada.ro",
    "player-022@cellada.ro",
    "player-023@cellada.ro",
    "player-024@cellada.ro",
    "player-029@cellada.ro",
    "player-033@cellada.ro",
    "player-034@cellada.ro",
    "player-039@cellada.ro",
    "player-072@cellada.ro",
    "player-081@cellada.ro",
    "player-087@cellada.ro",
    "player-088@cellada.ro",
    "player-101@cellada.ro",
    "player-102@cellada.ro",
    "player-103@cellada.ro",
    "player-107@cellada.ro",
    "player-111@cellada.ro",
    "player-112@cellada.ro",
    "player-115@cellada.ro",
    "player-124@cellada.ro",
    "player-125@cellada.ro",
    "player-127@cellada.ro",
    "player-128@cellada.ro",
    "player-131@cellada.ro",
    "marian.maican@cellada.ro",
    "creativegalaxysrl@gmail.com",
    "perfectstoresrl@gmail.com",
    "luisakkk@icloud.com",
    "brozba.fb09@gmail.com",
    "frogmari@yahoo.com",
    "chirculescu.daniel@gmail.com",
    "mihartiuliana1975@gmail.com",
    "kunstar73@gmail.com",
    "orsolya.t@yahoo.com",
    "marius.bvbgame@gmail.com",
    "jeans_shop95@yahoo.com",
    "sorin_morosanu1970@yahoo.com",
    "service.beroe@yahoo.com",
    "danielsutac@tropicala.ro",
    "danacaraseg@tropicala.ro",
    "mmv.morar@gmail.com",
    "ianulia84@yahoo.com",
    "cezianaslot@yahoo.com",
    "timwingames@gmail.com",
    "mailto:timwingames@gmail.com",
    "cristina.andreea613@gmail.com",
    "madalinaradulescu1811@gmail.com",
    "ady_rara@icloud.com",
    "martinalexandruflorin@yahoo.com",
    "bar.eurogames@yahoo.ro",
    "danyel_vis@yahoo.com",
    "dorinelud@yahoo.com",
    "daniel.epanu@yahoo.com",
    "epanu.office@yahoo.com",
    "cristiandiaconescu14@gmail.com",
    "jaravete.andrei@gmail.com",
    "dineaflorian@gmail.com",
    "digitalslotsclub@gmail.com",
    "denisa_racareanu@yahoo.com",
    "office@rexogame.ro",
    "cserban@minovo.ro",
    "floroiu.florin@mozzartbet.com",
    "dan2town@yahoo.com",
    "brunoslotsrl@gmail.com",
    "office@maximbet.ro",
    "caradunegames@gmail.com",
    "stanomirescuciprian@gmail.com",
    "raluca.bratu30@gmail.com",
    "todireanuraul@gmail.com",
    "gilcacristian78@gmail.com",
    "aparascai.adrian@icloud.com",
    "biancaberce@yahoo.com",
    "manexcompany@gmail.com",
    "manhattancasino2@gmail.com",
    "manhattancasino3@gmail.com",
    "manhattancasino4@gmail.com",
    "manhattancasino5@gmail.com",
    "manhattancasino6@gmail.com",
    "manhattanc58@gmail.com",
    "zoneclub2009@gmail.com",
    "valentinchirtes@gmail.com",
    "gabrielcimponeru@yahoo.com",
    "dumitru.mandres95@gmail.com",
    "ionitam620@gmail.com",
    "dragulescuraduflorin@gmail.com",
    "ignatcristian80@gmail.com",
    "cash.slot@yahoo.com",
    "doncashvip@yahoo.com",
    "brasov@gamesinternational.ro",
    "buzau@gamesinternational.ro",
    "bacau@gamesinternational.ro",
    "bucuresti@gamesinternational.ro",
    "office.blackstarslots@gmail.com",
    "denisa.bindeanu@yahoo.com",
    "incze_mariaandreea@yahoo.com",
    "matesan.anca@yahoo.com",
    "nonstop.timisoara@gmail.com",
    "cristina_eurogame@yahoo.com",
    "chindris.d@yahoo.com",
    "mali_manu7@yahoo.ro",
    "suport.sloturi@dms.ro",
    "sandu.cabuz@dms.ro",
    "bogdanlingurici@yahoo.com",
    "zamfirescu.iulian80@gmail.com",
    "draghici.ioan@gmail.com",
    "szekely_bela@yahoo.com",
    "nicoletacalugar79@gmail.com",
    "bojte_zsolt@yahoo.com",
    "cosmin_martin@yahoo.com",
    "aureliancirdu@gmail.com",
    "jeans_shop95@yahoo.com",
    "dananeacsu89@yahoo.com",
    "bagy232005@yahoo.com",
    "elena_velovici@yahoo.com",
    "blanzeanu.bogdan@gmail.com",
    "ciosan.florin@gmail.com",
    "playsys2005@gmail.com",
    "adi.zotic@yahoo.com",
    "nicolae.ionel1973@gmail.com",
    "pauldaineam@yahoo.com",
    "smily_andem@yahoo.com",
    "cristi.intergames@gmail.com",
    "montecarlo2bm@yahoo.com",
    "danimar798@gmail.com",
    "asb68@yahoo.com",
    "sandinexclubsrl@gmail.com",
    "fiffy1990@yahoo.com",
    "spingamestehnic@yahoo.com",
    "chgtimis@gmail.com",
    "lucian3084@gmail.com",
    "aurelian.caciulatu@yahoo.com",
    "valentina.simina15@gmail.com",
    "dorin.george19@yahoo.com",
    "office@dariosergiogames.ro",
    "micle.sorin@yahoo.com",
    "novagamessrl@outlook.com",
    "eldoradoegt@gmail.com",
    "bucuresti.salajan@eldoradoslots.ro",
    "bucuresti.gorjului@eldoradoslots.ro",
    "targoviste.independentei@eldoradoslots.ro",
    "buzau.unirii@eldoradoslots.ro",
    "timisoara.circumvalatiunii@eldoradoslots.ro",
    "timisoara.barnutiu@eldoradoslots.ro",
    "timisoara.rebreanu@eldoradoslots.ro",
    "timisoara.sagului@eldoradoslots.ro",
    "timisoara.severbocu@eldoradoslots.ro",
    "oradea.gojdu@eldoradoslots.ro",
    "oradea.transilvaniei@eldoradoslots.ro",
    "bucuresti.giurgiului@eldoradoslots.ro",
    "moisii.mihai@yahoo.com",
    "victor.capatan@superbet.ro",
    "stefikeller@gmail.com",
    "sandus@stanleybet.ro",
    "cnuta@stanleybet.ro",
    "amark@stanleybet.ro",
    "smodi@stanleybet.ro",
    "ciprian.novagames@yahoo.com",
    "gigi.rosca@gmail.com",
    "office2177@yahoo.com",
    "mihasiovi@yahoo.com",
    "iulian.saila.31@gmail.com",
    "luminitacojocarel@yahoo.com",
    "ivangabriel1980@gmail.com",
    "ecorallyteam@gmail.co",
    "anuscuta1234@gmail.com",
    "condorul.takewinbet@yahoo.com",
    "samaranewtradesrl@yahoo.com",
    "rusgabriela79@gmail.com",
    "anna.pienaru@gmail.com",
    "bogcos@gmail.com",
    "oberkapo@yahoo.com",
    "ionutz_chiper@yahoo.com",
    "tech@vivabet.ro",
    "baramadalin9@gmail.com",
    "ioji_szomoru@yahoo.com",
    "clearervenus118@gmail.com",
    "venusvs91@yahoo.com",
    "cristi.rusu@dms.ro",
    "adrian.coros@baumgames.ro",
    "corchis.vasi@baumgames.ro",
    "ionut.corlaciu@baumgames.ro",
    "claudiu.duda@baumgames.ro",
    "adrix@baumnet.com",
    "prosper@magic.ro",
    "galati@aristocrat.bet",
    "bucuresti@aristocrat.bet",
    "iasi1@aristocrat.bet",
    "iasi2@aristocrat.bet",
    "roman@aristocrat.bet",
    "suceava@aristocrat.bet",
    "gsava93@yahoo.com",
    "hgeo85@gmail.com",
    "silviumicu3@gmail.com",
    "skypyx@yahoo.com",
    "valentin@wynn.ro",
    "scmaxwinslots@gmail.com",
    "slotgrup@gmail.com",
    "vadim@cash-pot.ro",
    "coduri.egt@mplanet.ro",
    "office@loftslots.ro",
    "office.bigwin@gmail.com",
    "professionaldoublengames@yahoo.com",
    "manager.vaslui@dms.ro",
    "alex.rotaru84@gmail.com",
    "alexandru.catalin@dms.ro",
    "chis.george15@gmail.com",
    "andreipotap996@gmail.com",
    "manager.sali.oradea@dms.ro",
    "razvan.danila@dms.ro",
    "vlad.olaru@dms.ro",
    "andrei.suvac@dms.ro",
    "petrut.renta@dms.ro",
    "cristian.negru@dms.ro",
    "royal.amex@gmail.com",
    "emiliymoldovan@gmail.com",
    "liviubitzuvoicila@yahoo.com",
    "constanta.moloiu@gmail.com",
    "mihaelaion701@gmail.com",
    "excelbetprahova@gmail.com",
    "6.lupu.madalyn@gmail.com",
    "mateo.apetrei@dms.ro",
    "constantin.tapalaga@dms.ro",
    "florin.tase@dms.ro",
    "klorant@stanleybet.ro",
    "ionut.talpa@rivergrup.ro",
    "bogdan.nedelcu@intergameselect.com",
    "eduard.popa@dms.ro",
    "paul.ilinca@dms.ro",
    "mihai.punga@dms.ro",
  ];

  const deny_access_to_chat = [
    "bogdan.mihalte@metropolisgrup.ro",
    "augustin.tarpian@rivergrup.ro",
    "diana.popescu@rivergrup.ro",
    "bogdan.ciofu@yahoo.com",
    "soso.maciuca@yahoo.com",
    "rodica.rus@rivergrup.ro",
    "lucia.boros@rivergrup.ro",
    "raluca.sauca@rivergrup.ro",
    "sorin.dunca@rivergrup.ro",
    "vicky.tardea@rivergrup.ro",
    "ion.sandru@rivergrup.ro",
    "simona.gale@metropolisgrup.ro",
    "florin.bobeica@metropolisgrup.ro",
    "mircea.radulescu@rivergrup.ro",
    "cristina.hettman@rivergrup.ro",
    "daniela.oleinici@rivergrup.ro",
    "marcoltgheorghe@icloud.com",
    "andreea.scortanu@rivergrup.ro",
    "moldovan.calin@revital-games.ro",
    "Ionut.talpa@rivergrup.ro",
    "georgiana.rosioru@feg.eu",
    "corina.oprea@feg.eu",
    "grigorescu.alexandra@feg.eu",
    "admin@intergameselect.com",
    "d.gombos@seven.ro",
    "sz.jozsef@seven.ro",
    "fiordache@stanleybet.ro",
    "vitmesstilcontabilitate@gmail.com",
    "vladulescu1980@gmail.com",
    "vitmesstilgames@yahoo.ro",
    "dispecerat@stanleybet.ro",
    "transilvania.gold.games@gmail.com",
    "petronela.rizea@cellada.ro",
    "gamex_inter@yahoo.com",
    "cskgreatwin@outlook.com",
    "jeans_shop95@yahoo.co",
    "tatiana.aniculaesei@yahoo.com",
    "ciocan.lavinia@yahoo.com",
    "vierunicusor.96@gmail.com",
    "mindrescuoana@yahoo.com",
    "serban.georgiana15@yahoo.com",
    "carmenliliana2507@gmail.com",
    "barbu_camelia88@yahoo.com",
    "nicoletaovidenie@yahoo.com",
    "rebeca.calihman.btm@gmail.com",
    "cadu.daniel@yahoo.com",
    "rox_monde@yahoo.com",
    "player-003@cellada.ro",
    "player-006@cellada.ro",
    "player-008@cellada.ro",
    "player-011@cellada.ro",
    "player-012@cellada.ro",
    "player-014@cellada.ro",
    "player-015@cellada.ro",
    "player-017@cellada.ro",
    "player-018@cellada.ro",
    "player-019@cellada.ro",
    "player-021@cellada.ro",
    "player-022@cellada.ro",
    "player-023@cellada.ro",
    "player-024@cellada.ro",
    "player-029@cellada.ro",
    "player-033@cellada.ro",
    "player-034@cellada.ro",
    "player-039@cellada.ro",
    "player-072@cellada.ro",
    "player-081@cellada.ro",
    "player-087@cellada.ro",
    "player-088@cellada.ro",
    "player-101@cellada.ro",
    "player-102@cellada.ro",
    "player-103@cellada.ro",
    "player-107@cellada.ro",
    "player-111@cellada.ro",
    "player-112@cellada.ro",
    "player-115@cellada.ro",
    "player-124@cellada.ro",
    "player-125@cellada.ro",
    "player-127@cellada.ro",
    "player-128@cellada.ro",
    "player-131@cellada.ro",
    "marian.maican@cellada.ro",
    "creativegalaxysrl@gmail.com",
    "perfectstoresrl@gmail.com",
    "luisakkk@icloud.com",
    "brozba.fb09@gmail.com",
    "frogmari@yahoo.com",
    "chirculescu.daniel@gmail.com",
    "mihartiuliana1975@gmail.com",
    "kunstar73@gmail.com",
    "orsolya.t@yahoo.com",
    "marius.bvbgame@gmail.com",
    "jeans_shop95@yahoo.com",
    "sorin_morosanu1970@yahoo.com",
    "service.beroe@yahoo.com",
    "danielsutac@tropicala.ro",
    "danacaraseg@tropicala.ro",
    "mmv.morar@gmail.com",
    "ianulia84@yahoo.com",
    "cezianaslot@yahoo.com",
    "timwingames@gmail.com",
    "mailto:timwingames@gmail.com",
    "cristina.andreea613@gmail.com",
    "madalinaradulescu1811@gmail.com",
    "ady_rara@icloud.com",
    "martinalexandruflorin@yahoo.com",
    "bar.eurogames@yahoo.ro",
    "danyel_vis@yahoo.com",
    "dorinelud@yahoo.com",
    "daniel.epanu@yahoo.com",
    "epanu.office@yahoo.com",
    "cristiandiaconescu14@gmail.com",
    "jaravete.andrei@gmail.com",
    "dineaflorian@gmail.com",
    "digitalslotsclub@gmail.com",
    "denisa_racareanu@yahoo.com",
    "office@rexogame.ro",
    "cserban@minovo.ro",
    "floroiu.florin@mozzartbet.com",
    "dan2town@yahoo.com",
    "brunoslotsrl@gmail.com",
    "office@maximbet.ro",
    "caradunegames@gmail.com",
    "stanomirescuciprian@gmail.com",
    "raluca.bratu30@gmail.com",
    "todireanuraul@gmail.com",
    "gilcacristian78@gmail.com",
    "aparascai.adrian@icloud.com",
    "biancaberce@yahoo.com",
    "manexcompany@gmail.com",
    "manhattancasino2@gmail.com",
    "manhattancasino3@gmail.com",
    "manhattancasino4@gmail.com",
    "manhattancasino5@gmail.com",
    "manhattancasino6@gmail.com",
    "manhattanc58@gmail.com",
    "zoneclub2009@gmail.com",
    "valentinchirtes@gmail.com",
    "gabrielcimponeru@yahoo.com",
    "dumitru.mandres95@gmail.com",
    "ionitam620@gmail.com",
    "dragulescuraduflorin@gmail.com",
    "ignatcristian80@gmail.com",
    "cash.slot@yahoo.com",
    "doncashvip@yahoo.com",
    "brasov@gamesinternational.ro",
    "buzau@gamesinternational.ro",
    "bacau@gamesinternational.ro",
    "bucuresti@gamesinternational.ro",
    "office.blackstarslots@gmail.com",
    "denisa.bindeanu@yahoo.com",
    "incze_mariaandreea@yahoo.com",
    "matesan.anca@yahoo.com",
    "nonstop.timisoara@gmail.com",
    "cristina_eurogame@yahoo.com",
    "chindris.d@yahoo.com",
    "mali_manu7@yahoo.ro",
    "suport.sloturi@dms.ro",
    "sandu.cabuz@dms.ro",
    "bogdanlingurici@yahoo.com",
    "zamfirescu.iulian80@gmail.com",
    "draghici.ioan@gmail.com",
    "szekely_bela@yahoo.com",
    "nicoletacalugar79@gmail.com",
    "bojte_zsolt@yahoo.com",
    "cosmin_martin@yahoo.com",
    "aureliancirdu@gmail.com",
    "jeans_shop95@yahoo.com",
    "dananeacsu89@yahoo.com",
    "bagy232005@yahoo.com",
    "elena_velovici@yahoo.com",
    "blanzeanu.bogdan@gmail.com",
    "ciosan.florin@gmail.com",
    "playsys2005@gmail.com",
    "adi.zotic@yahoo.com",
    "nicolae.ionel1973@gmail.com",
    "pauldaineam@yahoo.com",
    "smily_andem@yahoo.com",
    "cristi.intergames@gmail.com",
    "montecarlo2bm@yahoo.com",
    "danimar798@gmail.com",
    "asb68@yahoo.com",
    "sandinexclubsrl@gmail.com",
    "fiffy1990@yahoo.com",
    "spingamestehnic@yahoo.com",
    "chgtimis@gmail.com",
    "lucian3084@gmail.com",
    "aurelian.caciulatu@yahoo.com",
    "valentina.simina15@gmail.com",
    "dorin.george19@yahoo.com",
    "office@dariosergiogames.ro",
    "micle.sorin@yahoo.com",
    "novagamessrl@outlook.com",
    "eldoradoegt@gmail.com",
    "bucuresti.salajan@eldoradoslots.ro",
    "bucuresti.gorjului@eldoradoslots.ro",
    "targoviste.independentei@eldoradoslots.ro",
    "buzau.unirii@eldoradoslots.ro",
    "timisoara.circumvalatiunii@eldoradoslots.ro",
    "timisoara.barnutiu@eldoradoslots.ro",
    "timisoara.rebreanu@eldoradoslots.ro",
    "timisoara.sagului@eldoradoslots.ro",
    "timisoara.severbocu@eldoradoslots.ro",
    "oradea.gojdu@eldoradoslots.ro",
    "oradea.transilvaniei@eldoradoslots.ro",
    "bucuresti.giurgiului@eldoradoslots.ro",
    "moisii.mihai@yahoo.com",
    "victor.capatan@superbet.ro",
    "stefikeller@gmail.com",
    "sandus@stanleybet.ro",
    "cnuta@stanleybet.ro",
    "amark@stanleybet.ro",
    "smodi@stanleybet.ro",
    "ciprian.novagames@yahoo.com",
    "gigi.rosca@gmail.com",
    "office2177@yahoo.com",
    "mihasiovi@yahoo.com",
    "iulian.saila.31@gmail.com",
    "luminitacojocarel@yahoo.com",
    "ivangabriel1980@gmail.com",
    "ecorallyteam@gmail.co",
    "anuscuta1234@gmail.com",
    "condorul.takewinbet@yahoo.com",
    "samaranewtradesrl@yahoo.com",
    "rusgabriela79@gmail.com",
    "anna.pienaru@gmail.com",
    "bogcos@gmail.com",
    "oberkapo@yahoo.com",
    "ionutz_chiper@yahoo.com",
    "tech@vivabet.ro",
    "baramadalin9@gmail.com",
    "ioji_szomoru@yahoo.com",
    "clearervenus118@gmail.com",
    "venusvs91@yahoo.com",
    "cristi.rusu@dms.ro",
    "adrian.coros@baumgames.ro",
    "corchis.vasi@baumgames.ro",
    "ionut.corlaciu@baumgames.ro",
    "claudiu.duda@baumgames.ro",
    "adrix@baumnet.com",
    "prosper@magic.ro",
    "galati@aristocrat.bet",
    "bucuresti@aristocrat.bet",
    "iasi1@aristocrat.bet",
    "iasi2@aristocrat.bet",
    "roman@aristocrat.bet",
    "suceava@aristocrat.bet",
    "gsava93@yahoo.com",
    "hgeo85@gmail.com",
    "silviumicu3@gmail.com",
    "skypyx@yahoo.com",
    "valentin@wynn.ro",
    "scmaxwinslots@gmail.com",
    "slotgrup@gmail.com",
    "vadim@cash-pot.ro",
    "coduri.egt@mplanet.ro",
    "office@loftslots.ro",
    "office.bigwin@gmail.com",
    "professionaldoublengames@yahoo.com",
    "manager.vaslui@dms.ro",
    "alex.rotaru84@gmail.com",
    "alexandru.catalin@dms.ro",
    "chis.george15@gmail.com",
    "andreipotap996@gmail.com",
    "manager.sali.oradea@dms.ro",
    "razvan.danila@dms.ro",
    "vlad.olaru@dms.ro",
    "andrei.suvac@dms.ro",
    "petrut.renta@dms.ro",
    "cristian.negru@dms.ro",
    "royal.amex@gmail.com",
    "emiliymoldovan@gmail.com",
    "liviubitzuvoicila@yahoo.com",
    "constanta.moloiu@gmail.com",
    "mihaelaion701@gmail.com",
    "excelbetprahova@gmail.com",
    "6.lupu.madalyn@gmail.com",
    "mateo.apetrei@dms.ro",
    "constantin.tapalaga@dms.ro",
    "florin.tase@dms.ro",
    "klorant@stanleybet.ro",
    "ionut.talpa@rivergrup.ro",
    "bogdan.nedelcu@intergameselect.com",
    "eduard.popa@dms.ro",
    "paul.ilinca@dms.ro",
    "mihai.punga@dms.ro",
  ];

  const see_personal_forms = [
    "devops@kodingtech.com",
    "daniel.niculescu@egt-bg.com",
    "razvan.binciu@egt-bg.com",
    "alexandru.ovedenie@egt.com",
  ];

  useEffect(() => {
    const userINFO = JSON.parse(localStorage.getItem("egt.user"));
    if (!userINFO) {
      shouldRedirectToLogin(true);
    } else {
      setRole(userINFO.role);
      setEmail(userINFO.username);
    }
  }, []);

  const deleteTokens = useCallback(() => {
    logoutLogs();
    localStorage.removeItem("egt.user");
    localStorage.removeItem("egt.authToken");
  }, []);

  async function logoutLogs() {
    const req = await fetch(process.env.REACT_APP_BASE_URL + "/users/logout", {
      method: "POST",
      headers: {
        Authorization: "bearer " + localStorage.getItem("egt.authToken"),
        "Content-Type": "application/json",
      },
    });
    if (req.status > 299) {
      throw new Error("Failed to log form");
    }
  }

  const handleDrawerToggle = () => {
    props.doOpen(!props.open);
  };

  if (redirectToLogin) {
    return <Redirect to="/"></Redirect>;
  }

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const newLogicCanAccess =
    hasPermission(role, "admin") || access_to_form.includes(email);
  const drawer = (
    <>
      {" "}
      <div className={classes.toolbar} />
      <List style={{ marginTop: "50%" }}>
        {!deny_access_to_chat.includes(email) && (
          <Link to="/chat" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Chat"
                disableTypography
                className={
                  location.pathname === "/chat"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        )}

        {newLogicCanAccess && (
          <Link to="/codes_form" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Form codes"
                disableTypography
                className={
                  location.pathname === "/codes_form"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        )}
        {newLogicCanAccess && (
          <Link to="/codes_form_list" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="List form codes"
                disableTypography
                className={
                  location.pathname === "/codes_form_list"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        )}
        {see_personal_forms.includes(email) && (
          <Link
            to="/personal_codes_form_list"
            style={{ textDecoration: "none" }}
          >
            <ListItem button disableRipple>
              <ListItemText
                primary="List personal form codes"
                disableTypography
                className={
                  location.pathname === "/personal_codes_form_list"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        )}
        {newLogicCanAccess && (
          <Link to="/form_reports" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Form reports"
                disableTypography
                className={
                  location.pathname === "/form_reports"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        )}
        <Link to="/form" style={{ textDecoration: "none" }}>
          <ListItem button disableRipple>
            <ListItemText
              primary="Table"
              disableTypography
              className={
                location.pathname === "/form"
                  ? classes.clicked
                  : classes.unClicked
              }
            />
          </ListItem>
        </Link>
        {hasPermission(role, "supervisor") ? (
          <Link to="/company" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Company"
                disableTypography
                className={
                  location.pathname === "/company"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "admin") ? (
          <Link to="/add" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Add Slot Machine"
                disableTypography
                className={
                  location.pathname === "/add"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "supervisor") ? (
          <Link to="/blocked_slots" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Control slot machines"
                disableTypography
                className={
                  location.pathname === "/blocked_slots"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "admin") ? (
          <Link to="/client_excel" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Add client excel"
                disableTypography
                className={
                  location.pathname === "/client_excel"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "admin") ? (
          <Link to="/account" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Account"
                disableTypography
                className={
                  location.pathname === "/account"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "admin") ? (
          <Link to="/admin_requests" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Admin Logs"
                disableTypography
                className={
                  location.pathname === "/admin_requests"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "admin") ? (
          <Link to="/requests" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="User Logs"
                disableTypography
                className={
                  location.pathname === "/requests"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "supervisor") ? (
          <Link to="/upload" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Bulk update"
                disableTypography
                className={
                  location.pathname === "/upload"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "admin") ? (
          <Link to="/reports" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Statistics"
                disableTypography
                className={
                  location.pathname === "/reports"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        <Link to="/" style={{ textDecoration: "none" }} onClick={deleteTokens}>
          <ListItem button disableRipple>
            <ListItemText
              primary="Log out"
              disableTypography
              style={{
                margin: "10% 0% 5% 15%",
                fontSize: "18px",
                fontWeight: 500,
                color: "#000",
              }}
            />
          </ListItem>
        </Link>
      </List>
    </>
  );

  return (
    <>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          className={classes.drawer}
          open={props.open}
          onClose={handleDrawerToggle}
          variant={"temporary"}
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant={"permanent"}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

export default AppDrawer;
